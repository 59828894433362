import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdminGuard } from './auth/admin.guard';
import { GvaUserGuard } from './auth/gva-user.guard';
import { HkUserGuard } from './auth/hk-user.guard';
import { LoginComponent } from './auth/login.component';
import { AppUnavailableComponent } from './core/app-unavailable.component';
import { EmployeeHandbookComponent } from './employee-handbook/employee-handbook.component';
import { EthicStandardsAndCorporateCultureComponent } from './ethic-standards-and-corporate-culture/ethic-standards-and-corporate-culture.component';
import { ExternalBusinessInterestDeclarationComponent } from './external-business-interest-declaration/external-business-interest-declaration.component';
import { FitAndProperDeclarationComponent } from './fit-and-proper-declaration/fit-and-proper-declaration.component';
import { GvaFitAndProperDeclarationComponent } from './geneva/gva-fit-and-proper-declaration/gva-fit-and-proper-declaration.component';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './home/landing.component';
import { PersonalAccountDealingDeclarationComponent } from './personal-account-dealing-declaration/personal-account-dealing-declaration.component';
import { PersonalAccountDealingPolicyComponent } from './personal-account-dealing-policy/personal-account-dealing-policy.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { LoginFailedComponent } from './auth/login-failed.component';
import { GvaFitAndProperDeclarationAdminDashboardComponent } from './geneva/gva-fit-and-proper-declaration/gva-fit-and-proper-declaration-admin-dashboard.component';
import { GvaAdminGuard } from './auth/gva-admin.guard';
import { ComplianceManualComponent } from './compliance-manual/compliance-manual.component';
import { UkUserGuard } from './auth/uk-user.guard';
import { UkStatementOfResponsibilitiesComponent } from './uk/uk-statement-of-responsibilities/uk-statement-of-responsibilities.component';
import { UkFitAndProperDeclarationComponent } from './uk/uk-fit-and-proper-declaration/uk-fit-and-proper-declaration.component';
import { UkSmcrComponent } from './uk/uk-smcr/uk-smcr.component';
import { UkAdminGuard } from './auth/uk-admin.guard';
import { UkComplianceManualComponent } from './uk/uk-compliance-manual/uk-compliance-manual.component';
import { HkDrBcpAcksComponent } from './hk/dr-bcp-acks/dr-bcp-acks.component';
import { HkAmlPolicyAcksComponent } from './hk/aml-policy-acks/aml-policy-acks.component';

const routes: Routes = [
  {
    path: 'admin-dashboard',
    canActivate: [MsalGuard, AdminGuard],
    component: AdminDashboardComponent
  },
  {
    path: 'compliance-manual',
    canActivate: [MsalGuard, HkUserGuard],
    component: ComplianceManualComponent
  },
  {
    path: 'employee-handbook',
    component: EmployeeHandbookComponent,
    canActivate: [MsalGuard, HkUserGuard]
  },
  {
    path: 'ethic-standards-and-corporate-culture',
    component: EthicStandardsAndCorporateCultureComponent,
    canActivate: [MsalGuard, HkUserGuard]
  },
  {
    path: 'external-business-interest-declaration',
    component: ExternalBusinessInterestDeclarationComponent,
    canActivate: [MsalGuard, HkUserGuard]
  },
  {
    path: 'fit-and-proper-declaration',
    component: FitAndProperDeclarationComponent,
    canActivate: [MsalGuard, HkUserGuard]
  },
  {
    path: 'gva',
    children: [
      {
        path: 'fit-and-proper-declaration',
        children: [
          {
            path: 'admin',
            component: GvaFitAndProperDeclarationAdminDashboardComponent,
            canActivate: [MsalGuard, GvaAdminGuard]
          },
          {
            path: ':email/:year/:month',
            component: GvaFitAndProperDeclarationComponent,
            canActivate: [MsalGuard, GvaAdminGuard]
          },
          {
            path: '',
            pathMatch: 'full',
            component: GvaFitAndProperDeclarationComponent,
            canActivate: [MsalGuard, GvaUserGuard]
          }
        ]
      }
    ]
  },
  {
    path: 'hk',
    children: [
      {
        path: 'aml-policy',
        component: HkAmlPolicyAcksComponent,
        canActivate: [MsalGuard, HkUserGuard]
      },
      {
        path: 'dr-bcp-plan',
        component: HkDrBcpAcksComponent,
        canActivate: [MsalGuard, HkUserGuard]
      }
    ]
  },
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'login', component: LoginComponent },
  {
    path: 'login-failed',
    component: LoginFailedComponent
  },
  {
    path: 'personal-account-dealing-declaration',
    component: PersonalAccountDealingDeclarationComponent,
    canActivate: [MsalGuard, HkUserGuard]
  },
  {
    path: 'personal-account-dealing-policy',
    component: PersonalAccountDealingPolicyComponent,
    canActivate: [MsalGuard, HkUserGuard]
  },
  {
    path: 'uk',
    children: [
      {
        path: 'compliance-manual',
        component: UkComplianceManualComponent,
        canActivate: [MsalGuard, UkUserGuard]
      },
      {
        path: 'fit-and-proper-declaration',
        component: UkFitAndProperDeclarationComponent,
        canActivate: [MsalGuard, UkUserGuard],
        pathMatch: 'full'
      },
      {
        path: 'fit-and-proper-declaration/:email',
        component: UkFitAndProperDeclarationComponent,
        canActivate: [MsalGuard, UkAdminGuard]
      },
      {
        path: 'smcr',
        component: UkSmcrComponent,
        canActivate: [MsalGuard, UkUserGuard],
        pathMatch: 'full'
      },
      {
        path: 'smcr/:email',
        component: UkSmcrComponent,
        canActivate: [MsalGuard, UkAdminGuard]
      },
      {
        path: 'statement-of-responsibilities',
        component: UkStatementOfResponsibilitiesComponent,
        canActivate: [MsalGuard, UkUserGuard],
        pathMatch: 'full'
      },
      {
        path: 'statement-of-responsibilities/:email',
        component: UkStatementOfResponsibilitiesComponent,
        canActivate: [MsalGuard, UkAdminGuard]
      }
    ]
  },
  { path: 'unavailable', component: AppUnavailableComponent },
  { path: '', pathMatch: 'full', component: LandingComponent }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
