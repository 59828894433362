import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComplianceManualService } from './compliance-manual.service';
import moment from 'moment';

@Component({
  selector: 'ad-compliance-manual',
  template: `
    <mat-card class="bg-white m-0">
      <mat-card-header>
        <mat-card-title>Compliance Manual</mat-card-title>
        <mat-card-subtitle>
          Please read the compliance manual and acknowledge by clicking the button at the end of the page.
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ad-custom-pdf-viewer
          fileName="ComplianceManual.pdf"
          (endOfDocumentReached)="isAtEndOfDocument = true"
        ></ad-custom-pdf-viewer>
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="alreadyAcked$ | async; else canAckBlock" mat-button color="primary" disabled>
          YOUR ALREADY ACKNOWLEDGED READING THIS DOCUMENT
        </button>
        <ng-template #canAckBlock>
          <button mat-button color="primary" [disabled]="!isAtEndOfDocument" (click)="acknowledge()">
            ACKNOWLEDGE AND AGREE
          </button>
        </ng-template>
      </mat-card-actions>
    </mat-card>
  `,
  standalone: false
})
export class ComplianceManualComponent implements OnInit {
  public isAtEndOfDocument = false;
  public alreadyAcked$: Observable<boolean>;

  private start: Moment;

  constructor(
    private router: Router,
    private complianceManualService: ComplianceManualService
  ) {}

  ngOnInit(): void {
    this.start = moment();
    this.alreadyAcked$ = this.complianceManualService
      .getMine$()
      .pipe(map((item) => (item ? item.acknowledged : false)));
  }

  public acknowledge(): void {
    this.complianceManualService
      .acknowledge$(moment.duration(moment().diff(this.start)).asMilliseconds())
      .subscribe(() => this.router.navigate(['/']));
  }
}
