<div class="container-fluid">
  <ad-nav-menu></ad-nav-menu>

  <div class="content">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    @if (!isIframe) {
      <router-outlet></router-outlet>
    }
    <footer class="mt-2 p-0"><small>&copy; 2025 CIGP</small></footer>
  </div>
</div>
