<mat-card class="bg-white m-0">
  <mat-card-header>
    <mat-card-title>Employee Fit &amp; Proper Declaration</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if (hasLastYearData) {
      <ad-alert-data-preloaded></ad-alert-data-preloaded>
    }
    <form [formGroup]="form">
      <h4>Section 1: Personal Particulars</h4>
      <p>Name: {{ employee }}</p>
      <p>Title: {{ corporateTitle }}</p>
      <mat-form-field>
        <input matInput placeholder="SFC License Number" formControlName="sfcLicenseNumber" />
        <mat-hint>If applicable</mat-hint>
      </mat-form-field>

      <!-- Section 2 -->
      <h4>Section 2: General Information</h4>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>I have read the following:</p>
              <ul>
                <li>
                  <a href="https://www.hklii.org/eng/hk/legis/ord/571/s399.html" target="_blank"
                    >Section 399 of the Securities and Future Ordinance</a
                  >
                  and understood its disclosing obligations; and
                </li>
                <li>
                  the SFC's &ldquo;<a
                    href="https://www.sfc.hk/-/media/EN/assets/components/codes/files-current/web/guidelines/fit-and-proper-guidelines/Fit-and-Proper-Guidelines.pdf"
                    target="_blank"
                    >Fit and Proper Guidelines</a
                  >&rdquo;.
                </li>
              </ul>
            </div>
            <mat-radio-group formControlName="section2Question1">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section2Question1').touched && form.get('section2Question1').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
      </ol>

      <!-- Section 3 -->
      <h4>Section 3: Disciplinary Actions and Investigations</h4>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Have:
              <ul>
                <li>you;</li>
                <li>a company of which you are or were a director;</li>
                <li>a company of which you are or were involved in the management; or</li>
                <li>a company of which you are or were a substantial shareholder,</li>
              </ul>
              ever been:
              <ul>
                <li>
                  refused or restricted from the right to carry on any trade, business or profession for which a
                  specific license,<br />
                  registration or other authorization is required by law;
                </li>
                <li>
                  censured, disciplined, or disqualified by any professional or regulatory body<br />
                  (including a stock or futures exchange) in relation to any trade, business, or profession; or
                </li>
                <li>
                  the subject of an investigation conducted by a regulatory or criminal investigatory body<br />
                  (i.e. disciplinary tribunal, examination authority, inspector appointed under any enactment, or other
                  regulatory body)?
                </li>
              </ul>
            </div>
            <mat-radio-group formControlName="section3Question1">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section3Question1').touched && form.get('section3Question1').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Are there any disciplinary actions or proceedings pending against:
              <ul>
                <li>you;</li>
                <li>a company of which you are or were a director;</li>
                <li>a company of which you are or were involved in the management; or</li>
                <li>
                  a company of which you are or were a substantial shareholder<br />
                  in relation to any trade, business, or profession?
                </li>
              </ul>
            </div>
            <mat-radio-group formControlName="section3Question2">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section3Question2').touched && form.get('section3Question2').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Have:
              <ul>
                <li>you;</li>
                <li>a company of which you are or were a director;</li>
                <li>a company of which you are or were involved in the management; or</li>
                <li>a company of which you are or were a substantial shareholder,</li>
              </ul>
              ever been:
              <ul>
                <li>investigated about offences involving fraud or dishonesty; or</li>
                <li>adjudged by a court to be civilly liable for fraud, dishonesty, or misfeasance; or</li>
                <li>any other economic crime?</li>
              </ul>
            </div>
            <mat-radio-group formControlName="section3Question3">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section3Question3').touched && form.get('section3Question3').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Have you ever been disqualified by a court from being a director of a corporation, or the equivalent in
              another jurisdiction?
            </div>
            <mat-radio-group formControlName="section3Question4">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section3Question4').touched && form.get('section3Question4').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Have you ever been charged with a criminal offence other than a minor traffic or littering offence?
            </div>
            <mat-radio-group formControlName="section3Question5">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section3Question5').touched && form.get('section3Question5').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Have you ever been convicted of a criminal offence other than a minor traffic or littering offence?
            </div>
            <mat-radio-group formControlName="section3Question6">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section3Question6').touched && form.get('section3Question6').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
      </ol>

      <!-- Section 4 -->
      <h4>Section 4: Financial Status</h4>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            Have you ever been party to any civil litigation in the past ten (10) years involving an amount in excess of
            HK$ 100,000 or equivalent?
            <mat-radio-group formControlName="section4Question1">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section4Question1').touched && form.get('section4Question1').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            Are you presently party to any civil litigation?
            <mat-radio-group formControlName="section4Question2">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section4Question2').touched && form.get('section4Question2').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            Are they any judgements or court orders with which you not complied?
            <mat-radio-group formControlName="section4Question3">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section4Question3').touched && form.get('section4Question3').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Have you ever:
              <ul>
                <li>been a party to a scheme of arrangement; or</li>
                <li>entered into any form of compromise with your creditor,</li>
              </ul>
              in the past ten (10) years involving an amount in excess of HK$ 100,000 or equivalent?
            </div>
            <mat-radio-group formControlName="section4Question4">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section4Question4').touched && form.get('section4Question4').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            Have you ever been bankrupt, or been served with a bankruptcy petition?
            <mat-radio-group formControlName="section4Question5">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section4Question5').touched && form.get('section4Question5').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            Have you ever been a director, substantial shareholder, or involved in the management of a corporation which
            was wound up<br />
            other than by a members&lsquo; voluntary wind up?
            <mat-radio-group formControlName="section4Question6">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section4Question6').touched && form.get('section4Question6').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            Have you ever been a partner of a firm which was dissolved other than with the consent of all the partners?
            <mat-radio-group formControlName="section4Question7">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section4Question7').touched && form.get('section4Question7').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
      </ol>

      <!-- Section 5 -->
      <h4>Section 5: Mental Health</h4>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            Have you ever been a patient as defined in section 2 of the Mental Health Ordinance?
            <mat-radio-group formControlName="section5Question1">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error *ngIf="form.get('section5Question1').touched && form.get('section5Question1').hasError('required')"
            >This field is required.</mat-error
          >
        </li>
      </ol>

      <!-- Section 6 -->
      <h4>Section 6: Additional Information</h4>
      <mat-hint
        >If you have answered &ldquo;Yes&rdquo; to any of the questions in Section 3, 4 or 5, please provide any
        relevant details below, and explain why your association with the licensed corporation will not affect its
        fitness and properness.</mat-hint
      >
      <mat-form-field style="margin-top: 1rem">
        <mat-label>Additional information</mat-label>
        <textarea matInput formControlName="additionalInformation" rows="4"></textarea>
        <mat-error *ngIf="form.get('additionalInformation').hasError('required')">This field is required.</mat-error>
      </mat-form-field>

      <ng-container *ngIf="alreadySubmitted">
        <h4>Acknowledgement</h4>
        <p>
          I, hereby confirm and declare that all the information provided herein to be, complete, true, and correct. I
          further understand and acknowledge that it is my personal responsibility to notify the licensed corporation as
          well as the SFC (for licenced individuals), immediately and in writing, in the event that any of the
          information contained herein changes.
        </p>
        <p>
          Name: {{ employee }}<br />
          Date: {{ declarationDate | date }}
        </p>
      </ng-container>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="alreadySubmitted; else canSubmitBlock" mat-button color="primary" disabled>
      YOU ALREADY SUBMITTED THIS DECLARATION
    </button>
    <ng-template #canSubmitBlock>
      <button mat-button color="primary" [disabled]="!form.valid" (click)="submitForm()">SUBMIT</button>
    </ng-template>
  </mat-card-actions>
</mat-card>
