<div class="card">
  <div class="card-header">
    <h5 class="card-title">Employee Fit &amp; Proper Declaration</h5>
    <h6 class="card-subtitle text-body-secondary">GENEVA</h6>
  </div>
  <div class="card-body">
    @if (hasLastYearData) {
      <ad-alert-data-preloaded-standalone />
    }
    <form [formGroup]="form">
      <h6>Section 1: Personal Particulars</h6>
      <p>Name: {{ user?.name }}</p>
      <p>Title: {{ user?.title }}</p>
      <mat-form-field style="width: 280px">
        <input matInput placeholder="SO-FIT Member #" formControlName="soFitNumber" />
        <mat-hint>If applicable</mat-hint>
      </mat-form-field>

      <!-- Section 2 -->
      <h6 class="mt-3">Section 2: General Information</h6>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div>
                I have read and understood the &ldquo;<a
                  href="https://cigpdeclstg.blob.core.windows.net/templates/gva-fit-and-proper-sofit-2024.pdf?sp=r&st=2024-09-05T02:23:22Z&se=2026-09-05T10:23:22Z&spr=https&sv=2022-11-02&sr=b&sig=qfsAW%2FGCwzqAKLknfLwssEsgnikCohkXpR9XUdtvd1k%3D"
                  target="_blank"
                  >R&egrave;glement de so-fit relatif aux obligations des assujettis à l'organisme de surveillance</a
                >&rdquo; of SO-FIT and understood its disclosing obligations, particularly those contained in its
                Chapter II.
              </div>
              @if (form.get('section2Question1').touched && form.get('section2Question1').hasError('required')) {
                <mat-error>You must answer &ldquo;Yes&rdquo; to this question.</mat-error>
              }
            </div>
            <mat-radio-group formControlName="section2Question1" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div>
                I have read and understood the
                <a
                  href="https://cigpdeclstg.blob.core.windows.net/templates/gva-fit-and-proper-code-of-conduct-2025.pdf?sp=r&st=2025-01-21T09:43:02Z&se=2035-01-21T17:43:02Z&spr=https&sv=2022-11-02&sr=b&sig=D0Lg%2BNQxH0WZ45ilcuZmUBcfXjDBa4FSbvC%2BdUhEVog%3D"
                  target="_blank"
                  >CIGP Code of Conduct</a
                >.
              </div>
              @if (form.get('section2Question2').touched && form.get('section2Question2').hasError('required')) {
                <mat-error>You must answer &ldquo;Yes&rdquo; to this question.</mat-error>
              }
            </div>
            <mat-radio-group formControlName="section2Question2" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div>
                I have read and understood the
                <a
                  href="https://cigpdeclstg.blob.core.windows.net/templates/gva-fit-and-proper-reglement-lba-2025.pdf?sp=r&st=2025-01-21T09:45:57Z&se=2035-01-21T17:45:57Z&spr=https&sv=2022-11-02&sr=b&sig=pQDUbhLHI5MbDNgglrUxWm7tX3L2CEO%2BJqzYMGt7nu8%3D"
                  target="_blank"
                  >CIGP AML Directive</a
                >.
              </div>
              @if (form.get('section2Question3').touched && form.get('section2Question3').hasError('required')) {
                <mat-error>You must answer &ldquo;Yes&rdquo; to this question.</mat-error>
              }
            </div>
            <mat-radio-group formControlName="section2Question3" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div>
                I have read and understood the
                <a
                  href="https://cigpdeclstg.blob.core.windows.net/templates/gva-fit-and-proper-activite-transfrontaliere-2025.pdf?sp=r&st=2025-01-21T09:46:45Z&se=2035-01-21T17:46:45Z&spr=https&sv=2022-11-02&sr=b&sig=1mAZyQ%2BnpmjRpdK9vvDlDo962ho1wKELwn6Xh6RqVZs%3D"
                  target="_blank"
                  >CIGP Cross Border Directive</a
                >.
              </div>
              @if (form.get('section2Question4').touched && form.get('section2Question4').hasError('required')) {
                <mat-error>You must answer &ldquo;Yes&rdquo; to this question.</mat-error>
              }
            </div>
            <mat-radio-group formControlName="section2Question4" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div>
                I have read and understood the
                <a
                  href="https://cigpdeclstg.blob.core.windows.net/templates/gva-fit-and-proper-suitability-appropriateness-directive-2025.pdf?sp=r&st=2025-01-21T09:47:55Z&se=2035-01-21T17:47:55Z&spr=https&sv=2022-11-02&sr=b&sig=JL750mgKHry1WG1kCfv1MELPDQc0Zc%2FdYGSd4DNBdSk%3D"
                  target="_blank"
                  >CIGP Suitability and Appropriateness Directive</a
                >.
              </div>
              @if (form.get('section2Question5').touched && form.get('section2Question5').hasError('required')) {
                <mat-error>You must answer &ldquo;Yes&rdquo; to this question.</mat-error>
              }
            </div>
            <mat-radio-group formControlName="section2Question5" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div>
                I agree to abide fully to CIGP policies and report any breaches to the Compliance Officer and the Senior
                Management. I understand and acknowledge that breaches may lead to disciplinary procedures by both CIGP
                and the Swiss regulatory body.
              </div>
              @if (form.get('section2Question6').touched && form.get('section2Question6').hasError('required')) {
                <mat-error>You must answer &ldquo;Yes&rdquo; to this question.</mat-error>
              }
            </div>
            <mat-radio-group formControlName="section2Question6" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
      </ol>

      <!-- Section 3 -->
      <h6>Section 3: Disciplinary Actions and Investigations</h6>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>In the past or the present, have you as:</p>
              <ul>
                <li>an individual;</li>
                <li>a director of a company;</li>
                <li>a management member of a company; or</li>
                <li>a substantial shareholder of a company;</li>
              </ul>
              <p>ever been:</p>
              <ul>
                <li>
                  refused or restricted from the right to carry on any trade, business or profession for which a
                  specific license,<br />
                  registration or other authorization is required by law;
                </li>
                <li>
                  censured, disciplined, or disqualified by any professional or regulatory body<br />
                  (including a stock or futures exchange) in relation to any trade, business, or profession; or
                </li>
                <li>
                  the subject of an investigation conducted by a regulatory or criminal investigatory body<br />
                  (i.e. disciplinary tribunal, examination authority, inspector appointed under any enactment, or other
                  regulatory body)?
                </li>
              </ul>
            </div>
            <mat-radio-group formControlName="section3Question1" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question1').touched && form.get('section3Question1').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>Are there any disciplinary actions or proceedings pending against you, as:</p>
              <ul>
                <li>an individual;</li>
                <li>a past or current director of a company;</li>
                <li>a past or current management member of a company; or</li>
                <li>
                  a past or current substantial shareholder of a company, in relation to<br />
                  any trade, business, or profession?
                </li>
              </ul>
            </div>
            <mat-radio-group formControlName="section3Question2" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question2').touched && form.get('section3Question2').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>In the past or the present, have you as:</p>
              <ul>
                <li>an individual;</li>
                <li>a director of a company;</li>
                <li>a management member of a company; or</li>
                <li>a substantial shareholder of a company;</li>
              </ul>
              <p>ever been:</p>
              <ul>
                <li>investigated about offences involving fraud or dishonesty; or</li>
                <li>adjudged or found by a court to be civilly liable for fraud, dishonesty, or misfeasance; or</li>
                <li>any other economic crime?</li>
              </ul>
            </div>
            <mat-radio-group formControlName="section3Question3" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question3').touched && form.get('section3Question3').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>
                Have you ever been disqualified by a court from being a director of a corporation, or the equivalent in
                another jurisdiction?
              </p>
            </div>
            <mat-radio-group formControlName="section3Question4" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question4').touched && form.get('section3Question4').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>Have you ever been charged with a criminal offence other than a minor traffic or littering offence?</p>
            </div>
            <mat-radio-group formControlName="section3Question5" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question5').touched && form.get('section3Question5').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>Have you ever been convicted of a criminal offence other than a minor traffic or littering offence?</p>
            </div>
            <mat-radio-group formControlName="section3Question6" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question6').touched && form.get('section3Question6').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>Are you presently party to any civil litigation?</p>
            </div>
            <mat-radio-group formControlName="section3Question7" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question7').touched && form.get('section3Question7').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>Are you in breach of any judgements or court orders?</p>
            </div>
            <mat-radio-group formControlName="section3Question8" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section3Question8').touched && form.get('section3Question8').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
      </ol>

      <!-- Section 3.1 -->
      <h6>Section 3.1: Identification of conflict of interests</h6>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>Do you have a power of attorney in your name on behalf of clients?</p>
            <mat-radio-group formControlName="section31Question1" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section31Question1').touched && form.get('section31Question1').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>Does your activity within CIGP enable you to benefit of retrocessions from third-parties?</p>
            <mat-radio-group formControlName="section31Question2" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section31Question2').touched && form.get('section31Question2').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>Are you trading in financial instruments for your own account with third parties?</p>
            <mat-radio-group formControlName="section31Question3" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section31Question3').touched && form.get('section31Question3').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>Have you ever received a political mandate?</p>
            <mat-radio-group formControlName="section31Question4" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section31Question4').touched && form.get('section31Question4').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>Have you received any gifts (vacation trip, invitation, etc.) in an amount exceeding CHF 250.00?</p>
            <mat-radio-group formControlName="section31Question5" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section31Question5').touched && form.get('section31Question5').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
      </ol>
      <p>
        <strong
          >If you answered &ldquo;Yes&rdquo; to any of the questions in section 3.1, please provide your explanation in
          Section 6: Additional Information.</strong
        >
      </p>
      <p>
        <strong
          >In the event that any of the above responses become affirmative during the year, the employee shall
          immediately notify the Compliance Officer.</strong
        >
      </p>

      <!-- Section 4 -->
      <h6>Section 4: Financial Status</h6>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>
              Have you ever been party to any civil litigation in the past ten (10) years involving an amount in excess
              of CHF 10,000 or equivalent?
            </p>
            <mat-radio-group formControlName="section4Question1" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section4Question1').touched && form.get('section4Question1').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p>Have you ever:</p>
              <ul>
                <li>been a party to a scheme of arrangement; or</li>
                <li>entered into any form of compromise with your creditor,</li>
              </ul>
              <p>in the past ten (10) years involving an amount in excess of CHF 10,000 or equivalent?</p>
            </div>
            <mat-radio-group formControlName="section4Question2" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section4Question2').touched && form.get('section4Question2').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>Have you ever been bankrupt, or been served with a bankruptcy petition?</p>
            <mat-radio-group formControlName="section4Question3" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section4Question3').touched && form.get('section4Question3').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>
              Have you ever been a director, substantial shareholder, or involved in the management of a corporation
              which was wound up<br />
              other than by a members’ voluntary wind up?
            </p>
            <mat-radio-group formControlName="section4Question4" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section4Question4').touched && form.get('section4Question4').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>
              Have you ever been a partner of a firm which was dissolved other than with the consent of all the
              partners?
            </p>
            <mat-radio-group formControlName="section4Question5" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section4Question5').touched && form.get('section4Question5').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
      </ol>

      <!-- Section 5 -->
      <h6>Section 5: Mental Health</h6>
      <ol>
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <p>
              Have you ever been professionally diagnosed as mentally incapacitated, defined as a person who<br />
              is incapable, by reason of mental incapacity, of managing and administering his property &amp; affairs?
            </p>
            <mat-radio-group formControlName="section5Question1" style="min-width: 150px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          @if (form.get('section5Question1').touched && form.get('section5Question1').hasError('required')) {
            <mat-error>This field is required.</mat-error>
          }
        </li>
      </ol>

      <!-- Section 6 -->
      <h6>Section 6: Additional Information</h6>
      <mat-hint
        >If you have answered &ldquo;Yes&rdquo; to any of the questions in Section 3, 4 or 5, please provide any
        relevant details below, and explain why your association with CIGP will not affect its fitness and
        properness.</mat-hint
      >
      <mat-form-field style="margin-top: 1rem">
        <mat-label>Additional information</mat-label>
        <textarea matInput formControlName="additionalInformation" rows="6"></textarea>
        @if (form.get('additionalInformation').touched && form.get('additionalInformation').hasError('required')) {
          <mat-error>This field is required.</mat-error>
        }
      </mat-form-field>

      <h6>Acknowledgement</h6>
      <p>
        I, hereby confirm and declare that all the information provided herein to be, complete, true, and correct. I
        further understand and acknowledge that it is my personal responsibility to notify CIGP Compagnie
        d'Investissements et de Gestion Priv&eacute;e SA, immediately and in writing, in the event that any of the
        information contained herein changes.
      </p>
      <p>
        Name: {{ user?.name }}<br />
        Date: {{ declarationDate | date }}
      </p>
    </form>
    <div class="btn-group">
      @if (!isView) {
        @if (alreadySubmitted) {
          <button class="btn btn-primary" disabled>YOU ALREADY SUBMITTED THIS DECLARATION</button>
        } @else {
          <button
            class="btn btn-primary"
            [disabled]="!form.valid || !form.get('additionalInformation').valid"
            (click)="submitForm()"
          >
            SUBMIT
          </button>
        }
      }
      <button class="btn btn-secondary" (click)="location.back()">BACK</button>
    </div>
  </div>
</div>
