import { Component } from '@angular/core';
import { DeclarationsAuthService } from '../auth/declarations-auth.service';
import { TitleService } from '../core/title.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ad-nav-menu',
  standalone: false,
  template: `
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
      @if (loggedIn) {
        <div class="container-fluid d-flex justify-content-between">
          <a class="navbar-brand" [routerLink]="['/']">
            <img src="assets/cigp-logo.png" style="width: 40px" alt="CIGP" />
            <span class="mx-3">{{ title }}</span>
            @if (!production) {
              <span class="badge text-success-emphasis bg-success-subtle">TEST</span>
            }
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="me-auto"></div>
            <ul class="navbar-nav d-flex align-items-center">
              @if (feedbackForm) {
                <li class="nav-item">
                  <a class="nav-link" [href]="feedbackForm" target="_blank" style="width: 120px" class="text-black-50"
                    >FEEDBACK</a
                  >
                </li>
              }
              @if (userManual) {
                <li class="nav-item">
                  <a class="nav-link" [href]="userManual" target="_blank"
                    ><span class="bi bi-question-circle" aria-hidden="true"></span
                  ></a>
                </li>
              }

              <button mat-button [matMenuTriggerFor]="userMenu">{{ menuLabel | uppercase }}</button>
              <mat-menu #userMenu="matMenu">
                <button mat-menu-item (click)="logout()"><i class="bi bi-box-arrow-right"></i> LOGOUT</button>
              </mat-menu>
            </ul>
          </div>
        </div>
      }
    </nav>
  `
})
export class NavMenuComponent {
  public production = environment.production;
  public userManual = environment.userManual;
  public feedbackForm = environment.feedbackForm;

  constructor(
    private declarationsAuthService: DeclarationsAuthService,
    private titleService: TitleService
  ) {}

  public get loggedIn(): boolean {
    return this.declarationsAuthService.loggedIn;
  }

  public get title(): string {
    const pageTitle = this.titleService.title;

    if (pageTitle && pageTitle.length > 0) {
      return `CIGP Declarations - ${pageTitle}`;
    } else {
      return `CIGP Declarations`;
    }
  }

  public get menuLabel(): string {
    return this.declarationsAuthService.loggedIn ? this.declarationsAuthService.account.name : 'LOGIN';
  }

  public logout(): void {
    this.declarationsAuthService.logout$().subscribe();
  }
}
